import React, { useContext, useEffect, useState } from "react";
import PaymentMethodCard from "../paymentMethods/PaymentMethodCard";
import { CheckoutContext } from "../../context/CheckoutContext";
import CheckoutService from "../../services/CheckoutService";
import { handleCheckoutError } from "../../utils/checkout";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import StripeCheckout from "../common/StripeCheckout";
import PaymentSource from "../payment_sources/PaymentSource";
import { navigate } from "@reach/router";
import { S3_ENDPOINT } from "../../utils";
import { PaymentSourcesContext } from "../../context/PaymentSourcesContext";

const CheckoutPaymentMethods = ({
  class_package_id,
  paymentMethod,
  setPaymentMethod,
  installmentsOptions,
}) => {
  const [installments, setInstallments] = useState(1);
  const [processing, setProcessing] = useState(false);
  const { paquete, discountCode } = useContext(CheckoutContext);
  const { user } = useContext(AuthContext);

  const { alert } = useContext(ModalContext);

  const { payment_sources, getPaymentSources } = useContext(
    PaymentSourcesContext
  );
  useEffect(() => {
    if (user !== null) {
      getPaymentSources();
    }
  }, [user]);

  const renderPaymentSources = () => {
    if (payment_sources && payment_sources !== null) {
      return payment_sources
        .filter((metodo) => metodo.source_id !== null)
        .map((metodo) => (
          <PaymentSource
            payment_source={metodo}
            paymentMethod={paymentMethod}
            key={metodo.payment_source_id}
            setPaymentMethod={setPaymentMethod}
            selectedInstallments={installments}
            installmentsOptions={installmentsOptions}
            setSelectedInstallments={setInstallments}
          />
        ));
    }
  };

  const renderTransfer = () => {
    if (paquete && paquete !== null) {
      if (paquete.price_id === null) {
        return (
          <PaymentMethodCard
            name="transfer"
            label="Transferencia y Depósito"
            selected={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          >
            {paymentMethod === "transfer" && (
              <img
                src={`${S3_ENDPOINT}/transfer.jpeg`}
                style={{ maxWidth: 400 }}
              />
            )}
          </PaymentMethodCard>
        );
      }
    }
  };

  const renderPayPal = () => {
    return (
      <PaymentMethodCard
        name="paypal"
        label="PayPal"
        description="Se te cobrará un 6% de comisión"
        selected={paymentMethod}
        setPaymentMethod={setPaymentMethod}
      >
        <div
          id="paypal-button"
          style={{
            visibility: paymentMethod === "paypal" ? "visible" : "hidden",
          }}
        ></div>
      </PaymentMethodCard>
    );
  };

  const handleError = (message) => {
    setProcessing(false);
    alert(message);
  };

  const handleSuccess = (purchase_id) => {
    setProcessing(false);
    navigate(`/gracias/${purchase_id}`);
  };

  const handleSubmit = () => {
    setProcessing(true);
    CheckoutService.postCheckout(class_package_id, discountCode, paymentMethod)
      .then(async (res) => {
        const { purchase_id } = res.data;
        handleSuccess(purchase_id);
      })
      .catch((error) => handleCheckoutError(error, handleError));
  };

  const renderPago = () => {
    if (user !== null) {
      return (
        <div className="oveflow-hidden">
          <h3>Forma de Pago</h3>
          {renderPaymentSources()}
          <PaymentMethodCard
            name="card"
            label="Tarjeta de Crédito/Débito"
            selected={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          >
            {paymentMethod === "card" && (
              <StripeCheckout
                element_id={class_package_id}
                discountCode={discountCode}
                installmentsOptions={installmentsOptions}
              />
            )}
          </PaymentMethodCard>
          <div className="container-fluid px-0 text-right">
            {!["card", "paypal", "transfer"].includes(paymentMethod) && (
              <button
                disabled={processing}
                onClick={handleSubmit}
                className="btn btn-primary btn-lg"
              >
                {processing ? (
                  <div className="spinner-border"></div>
                ) : (
                  "Pagar Ahora"
                )}
              </button>
            )}
          </div>
        </div>
      );
    }
  };

  return <div>{renderPago()}</div>;
};

export default CheckoutPaymentMethods;
